import React from "react";

import {Link} from 'react-router-dom';

import './estilo.css';

import Topo from "../../componentes/Topo";
import Rodape from "../../componentes/Rodapé";

import Lupa from "../../assets/lupa.svg";
import Proposta from "../../assets/proposta.svg";
import Grafico from "../../assets/graphic.svg";

import Janela from '../../assets/janela.svg';
import Medalha from '../../assets/medalha.svg';
import Exclusivo from '../../assets/exclusivo.svg';


const PaginaHome = () => (
    <div>
        <Topo />
        <main>
             {/* SEÇÃO CAPA */}
            <section className="secao-capa">
                <div className="titulo limita-largura">
                    <p>Investir</p>
                    <h1>nunca</h1>
                    <p>foi tão fácil.</p>
                    <div className="botao" >
                    <Link to='/abra-conta'><button>Abra sua conta</button></Link>
                    </div>
                </div>
            </section>

            <section className="secao-porque">
                <div>
                    <div className="limita-largura">
                        <h2 className="titulo-secao">Como trabalhamos</h2>
                    </div>

                    <div className="cards limita-largura">
                        <div className="card">
                            <h4>Diagnóstico</h4>
                            <img alt="Lupa" src={Lupa}></img>
                            <p>Assim como um médico, realizamos um diagnóstico de acordo com as suas necessidades e objetivos, alinhado sempre com o seu perfil de investidor.</p>
                        </div>
                        <div className="card">
                            <h4>Proposta</h4>
                            <img alt="Lupa" src={Proposta}></img>
                            <p>Após compreendermos todas as suas necessidades, elaboraremos uma proposta de valor para otimizar seus investimentos.</p>
                        </div>
                        <div className="card">
                            <h4>Ação</h4>
                            <img alt="Lupa" src={Grafico}></img>
                            <p>Usufrua da plataforma da XP para selecionar os melhores investimentos disponíveis no mercado.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="secao-invista">
                <div className="limita-largura">
                    <h2 className="titulo-secao">Invista conosco</h2>
                </div>

                <div className="cards limita-largura">
                        <div className="card">
                            <h4>Transparência</h4>
                            <img alt="Lupa" src={Janela}></img>
                            <p>Relação 100% transparente, onde o cliente decide qual será a remuneração do assessor.</p>
                        </div>
                        <div className="card">
                            <h4>Qualificação</h4>
                            <img alt="Lupa" src={Medalha}></img>
                            <p>Profissionais altamente qualificados para melhor atender você.</p>
                        </div>
                        <div className="card">
                            <h4>Exclusividade</h4>
                            <img alt="Lupa" src={Exclusivo}></img>
                            <p>Atendimento totalmente exlcusivo e personalizado para uma melhor experiência.</p>
                        </div>
                    </div>
            </section>
        </main>
        <Rodape />
  </div>
);

export default PaginaHome;