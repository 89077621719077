import React from "react";

import './estilo.css';

import Topo from "../../componentes/Topo";
import Rodape from "../../componentes/Rodapé";

import LogoTelefone from '../../assets/logo-telefone.svg';
import LogoEmail from '../../assets/email.svg';

const PaginaContato = () => (
    <div>
        <Topo />
        <main>
            <section className="secao-fale-conosco">
                <div className="limita-largura fale-conosco">
                    <div className="titulo-fale-conosco">
                        <h1>Fale conosco</h1>
                    </div>

                    <div className="fale-conosco-contatos">
                        <div className="fale-conosco-mail">
                            <img alt="Logo Email" src={LogoEmail}></img>
                            <a href="mailto:atendimento@beneficiumai.com.br? subject='Novo cliente' " target="_blank" rel="noreferrer">atendimento@beneficiumai.com.br</a>
                        </div>

                        <div className="fale-conosco-telefone">
                            <img alt="Logo Telefone" src={LogoTelefone}></img>
                            <a href="tel: +558535120757" target="_blank" rel="noreferrer">(85) 3512-0757</a>
                        </div>
                    </div>
                </div>
            </section>
        </main>
        <Rodape />
    </div>
);

export default PaginaContato;