import React from "react";
import './estilo.css';

import Topo from "../../componentes/Topo";
import Rodape from "../../componentes/Rodapé";

const PaginaQuem = () => (
    <div>
        <Topo />
        <main>
            <section className="secao-quem-somos">
                <div>
                    <h1 className="titulo-pagina-quem">Sobre nós...</h1>
                </div>

                <div className="corpo-texto limita-largura">
                    <p>Na Beneficium Assessoria de Investimentos, entendemos que cada indivíduo tem metas financeiras únicas, e é nossa missão ajudar você a alcançar esses objetivos de forma sólida e sustentável. Somos uma equipe dedicada de profissionais financeiros comprometidos em fornecer a você as melhores estratégias de investimento e aconselhamento personalizado para otimizar seus recursos e construir um futuro financeiramente sólido.</p>

                    <p>Acreditamos que a excelência é a base de qualquer parceria duradoura. Nossos consultores financeiros são altamente qualificados e comprometidos em oferecer a você os melhores serviços. Cada membro de nossa equipe é orientado por um compromisso inabalável com a integridade, transparência e ética profissional. Buscamos constantemente aprimorar nossas habilidades e conhecimentos para garantir que você receba a orientação mais atualizada e informada.</p>

                    <p>Entendemos que as necessidades financeiras são diversas e evoluem ao longo do tempo. Nossa abordagem é totalmente personalizada, levando em consideração seus objetivos específicos, tolerância ao risco, horizonte de investimento e situação financeira atual. Trabalhamos em estreita colaboração com você para desenvolver um plano de investimento adaptado às suas necessidades individuais, proporcionando a tranquilidade necessária para enfrentar os desafios e aproveitar as oportunidades do mercado.</p>

                    <p>Acreditamos na importância da diversificação de portfólio para gerenciar riscos e buscar retornos consistentes ao longo do tempo. Nossos consultores especializados utilizam estratégias inovadoras, combinando análises fundamentais e técnicas, para identificar oportunidades de investimento que se alinham aos seus objetivos financeiros. Mantemos uma abordagem disciplinada e focada, ajustando estratégias conforme necessário para garantir que sua carteira esteja sempre otimizada.</p>

                    <p>Na Beneficium Assessoria de Investimentos, acreditamos que a educação financeira é a chave para a tomada de decisões informadas e bem-sucedidas. Oferecemos recursos educacionais abrangentes, seminários e webinars para capacitar nossos clientes a entenderem melhor os princípios fundamentais dos investimentos e tomarem decisões conscientes.</p>

                    <p>Para nós, cada cliente é uma parceria valiosa. Estamos comprometidos em construir relacionamentos duradouros baseados na confiança, comunicação aberta e resultados tangíveis. Ao escolher a Beneficium Assessoria de Investimentos, você não apenas obtém serviços financeiros de alta qualidade, mas também um parceiro comprometido em ajudá-lo a realizar seus sonhos financeiros.</p>
                </div>
            </section>
        </main>
        <Rodape />
    </div>
);

export default PaginaQuem;