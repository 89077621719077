import React from "react";
import './estilo.css';
import { Link } from "react-router-dom";

import XP from '../../assets/logo-xp-branca.webp';
import Beneficium from '../../assets/logo-beneficium.png';

function Topo (){
    return (
        <header>
            <div className="conteudo-topo limita-largura">
                <div className="logos">
                    <img alt="Logo Beneficium" className="logo-bene" src={Beneficium}></img>
                    <img alt="Logo XP" className="logo-xp" src={XP}></img>
                </div>

                <nav className="lista-navegacao">
                    <Link className="a" to='/'>Home</Link>
                    <Link className="a" to='/contato'>Contato</Link>
                    <Link className="a" to='/sobre'>Quem somos</Link>
                </nav>

                <div className="botao-abra-conta">
                    <Link to='/abra-conta'><button>Abra sua conta</button></Link>
                </div>
            </div>
        </header>
    );
}

export default Topo;