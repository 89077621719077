import React from "react";
import './estilo.css';

import Topo from "../../componentes/Topo";
import Rodape from "../../componentes/Rodapé";

import Identidade from '../../assets/identidade.svg';
import Lampada from '../../assets/lampada.svg'

const PaginaAbrirConta = () => (
    <div>
        <Topo />
        <main>
            <div className="limita-largura abrir-conta">
                <h1>Abra sua conta</h1>
                <div className="identidade">
                    <img alt="Identidade" src={Identidade}></img>
                    <p>Esteja com seu documento de identificação em mãos.</p>
                </div>
                <div className="identidade">
                    <img alt="lampada" src={Lampada}></img>
                    <p>Priorize um ambiente iluminado</p>
                </div>
                
                <p className="clique-aqui"><span><a href="https://cadastro.xpi.com.br/desktop/step/1?assessor=A45382" target="_blank" rel="noreferrer">Clique aqui</a></span> para abrir sua conta</p>
            </div>
        </main>
        <Rodape />
    </div>
);

export default PaginaAbrirConta;